/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import Img from "gatsby-image"

const IndustryNews = props => {
  return (
    <>
      {props.industry.nodes.map(f => (
        <div>
          {f.homepage.industryNews ? (
            <div className="grid-container gap-xl posts">
              <h2>Industry News</h2>
              <ul className="grid-x grid-margin-x grid-margin-y">
                {f.homepage?.industryNews?.map(e => (
                  <div className="large-3 medium-6 small-12 cell">
                    <div>
                      <div key={e.id} className="IndustryNews__img_holder">
                        <Img
                          fluid={
                            e?.featuredImage?.node?.localFile?.childImageSharp
                              ?.fluid
                          }
                          alt={e.title}
                        />
                        <Link
                          href={e?.newsLinks?.linkUrl?.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="news-button">Read more</span>
                        </Link>
                      </div>
                      <span className="news-category">{e?.date}</span>
                      <h3>
                        <Link
                          href={e?.newsLinks?.linkUrl?.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {e.title}
                        </Link>
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: e?.content.slice(0, 200),
                        }}
                      ></div>
                      <div className="read-more">
                        <Link
                          href={e?.newsLinks?.linkUrl?.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read more..
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      ))}
    </>
  )
}

export default IndustryNews
