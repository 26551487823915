import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        wpHomepage {
          homepage {
            footerImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData =
        data.wpHomepage.homepage?.footerImage?.localFile?.childImageSharp?.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        ></BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: center top;
  background-repeat: repeat-y;
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
`

export default StyledBackgroundSection
