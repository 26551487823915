/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import dayjs from "dayjs"
import Layout from "../components/layout"
import Footer from "../components/footer"
import SEO from "../components/seo"
import BgImage from "../components/ImageBackground"
import BgImageFooter from "../components/Homepage/ImageBackgroundFooter"
import News from "../components/news"
import IndustryNews from "../components/industryNews"
import { useState } from "react"
import useEmblaCarousel from "embla-carousel-react"
import Fade from "embla-carousel-fade"
import Autoplay from "embla-carousel-autoplay"
import { DotButton, useDotButton } from "../components/EmblaCarouselDotButton"
import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from "../components/EmblaCarouselArrowButtons"

const IndexPage = props => {
  // console.log(props)
  const [showMore, setShowMore] = useState(3)
  const seoTitle = props.data.wpHomepage.homepage?.seoMetaTitle
  const seoDescription = props.data.wpHomepage.homepage?.seoMetaDescription
  //console.log(props)

  const eventPages = props.data.allWpEventPage.nodes
  const simpleEventPages = props.data.allWpSimpleEvent?.nodes
  const meetupEventPages = props.data.allWpMeetupEvent?.nodes

  const together = eventPages.concat(meetupEventPages, simpleEventPages)
  const sortedArray = together.sort(
    (a, b) =>
      new Date(a?.endDate || a.cYAN?.end || a.simpleEvents?.end) -
      new Date(b?.endDate || b.cYAN?.end || b.simpleEvents?.end)
  )
  //console.log(sortedArray)

  const filtering = sortedArray
    .filter(a => {
      return dayjs(new Date()).isBefore(
        a?.endDate || a.cYAN?.end || a.simpleEvents?.end
      )
    })
    .sort(
      (a, b) =>
        new Date(a?.endDate || a.cYAN?.end || a.simpleEvents?.end) -
        new Date(b?.endDate || b.cYAN?.end || b.simpleEvents?.end)
    )
  //console.log(filtering.length)

  const addMore = () => {
    setShowMore(4)
  }

  const addLess = () => {
    setShowMore(3)
  }
  const filterFiltering = filtering?.filter(e => {
    return !e?.content?.includes("#@#")
  })
  //console.log(filterFiltering)

  function formatDate(date) {
    let d = new Date(date)
    return d.toLocaleString("en-us", {
      day: "numeric",
      year: "numeric",
      month: "short",
    })
  }

  function checkSameDate(date1, date2) {
    if (date1 === date2) {
      return date1
    } else {
      return `${date1} - ${date2}`
    }
  }

  let meetupEventPages2 = props.data.allWpMeetupEvent?.nodes
  meetupEventPages2.map(
    element => (element.startDate = formatDate(element.startDate))
  )
  meetupEventPages2.map(
    element => (element.endDate = formatDate(element.endDate))
  )
  meetupEventPages2.map(
    element =>
      (element.meetupDateRange = checkSameDate(
        element.startDate,
        element.endDate
      ))
  )

  // Slider
  const options = {
    loop: true,
    align: "center",
    containScroll: false,
  }
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Fade(),
    Autoplay({ playOnInit: true, delay: 3000 }),
  ])

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi
  )

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi)

  return (
    <Layout>
      <SEO title={seoTitle} defaultName="Home" description={seoDescription} />
      <div className="hero gap-xl">
        <div className="embla">
          <div className="embla__viewport" ref={emblaRef}>
            <div className="embla__container">
              {props?.data?.wpHomepage?.homepage?.slider.map(e => {
                return (
                  <div className="hero-section embla__slide">
                    <Img
                      className="embla__slide__img"
                      fluid={e?.image?.localFile.childImageSharp.fluid}
                      alt="conference"
                    />
                    <div className="hero-section-banner">
                      <div className="grid-container">
                        {props.data.wpHomepage.homepage?.heroText ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: props.data.wpHomepage.homepage.heroText,
                            }}
                          ></div>
                        ) : null}
                        {props.data.wpHomepage.homepage?.registerNow ? (
                          <a
                            href={
                              props.data.wpHomepage.homepage?.registerNow?.url
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button
                              className="hero-section-buttons"
                              aria-label="Button"
                            >
                              {
                                props.data.wpHomepage.homepage?.registerNow
                                  ?.title
                              }
                            </button>
                          </a>
                        ) : null}
                        {props.data.wpHomepage.homepage?.showDescription ? (
                          <a
                            href={
                              props.data.wpHomepage.homepage?.showDescription
                                ?.url
                            }
                          >
                            <button
                              className="hero-section-buttons"
                              aria-label="Button"
                            >
                              {
                                props.data.wpHomepage.homepage?.showDescription
                                  ?.title
                              }
                            </button>
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {scrollSnaps.length > 1 ? (
          <div className="grid-container">
            <div className="embla__controls">
              <div className="embla__buttons">
                <div className="embla__dots">
                  {scrollSnaps.map((_, index) => (
                    <DotButton
                      key={index}
                      onClick={() => onDotButtonClick(index)}
                      className={"embla__dot".concat(
                        index === selectedIndex ? " embla__dot--selected" : ""
                      )}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="grid-container">
        <div className="grid-x grid-margin-x grid-margin-y">
          <div className="large-6 cell">
            {props.data.wpHomepage.homepage?.introduction ? (
              <div
                className="intro-text"
                dangerouslySetInnerHTML={{
                  __html: props.data.wpHomepage.homepage.introduction,
                }}
              ></div>
            ) : null}
            <button style={{ marginBottom: `60px` }}>
              <Link to="/about/">More</Link>
            </button>
          </div>
          <div className="large-6 cell">
            <h3
              sx={{
                color: `#faa23a`,
                position: `relative`,
                width: `max-content`,
              }}
            >
              What's new
            </h3>
            <div className="large-6 medium-12 small-12 cell">
              {!!props?.data?.wpHomepage?.homepage?.eventPreview &&
              props?.data?.wpHomepage?.homepage?.eventPreview[0]?.__typename ===
                "WpEventPage" ? (
                <div className="upcoming" sx={{ width: `100%` }}>
                  <Link
                    to={`event/${props.data.wpHomepage.homepage.eventPreview[0]?.slug}`}
                  >
                    <div className="upcoming-events">
                      {props.data.wpHomepage.homepage.eventPreview[0]?.title ? (
                        <h3 className="upcoming-headline">
                          {
                            props.data.wpHomepage.homepage.eventPreview[0]
                              ?.title
                          }
                        </h3>
                      ) : null}
                      <div className="upcoming-subline">
                        {props.data.wpHomepage.homepage.eventPreview[0]
                          ?.excerpt ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                props.data.wpHomepage.homepage.eventPreview[0]
                                  ?.excerpt,
                            }}
                          ></div>
                        ) : null}
                      </div>
                    </div>
                    <Img
                      fluid={
                        props.data.wpHomepage.homepage.eventPreview[0]?.cYAN
                          ?.featuredImage?.localFile?.childImageSharp?.fluid
                      }
                      style={{
                        width: `100%`,
                        height: `100%`,
                        objectFit: `cover`,
                      }}
                    />
                  </Link>
                </div>
              ) : !!props?.data?.wpHomepage?.homepage?.eventPreview &&
                props?.data?.wpHomepage?.homepage?.eventPreview[0]
                  ?.__typename === "WpSimpleEvent" ? (
                <div className="upcoming" sx={{ width: `100%`, mb: `50px` }}>
                  <Link
                    to={`event/${props.data.wpHomepage.homepage.eventPreview[0]?.slug}`}
                  >
                    <Img
                      fluid={
                        props.data.wpHomepage.homepage.eventPreview[0]
                          ?.simpleEvents?.heroImage?.localFile?.childImageSharp
                          ?.fluid
                      }
                      style={{
                        width: `100%`,
                        height: `100%`,
                        objectFit: `cover`,
                      }}
                    />
                    <div className="upcoming-simple-events">
                      <h4 sx={{ fontSize: `18px`, fontWeight: `bold` }}>
                        {props.data.wpHomepage.homepage.eventPreview[0]?.title}
                      </h4>
                      <p sx={{ mb: `0px` }}>
                        {props.data.wpHomepage.homepage.eventPreview[0]?.cYAN
                          ?.eventDateRange ||
                          props.data.wpHomepage.homepage.eventPreview[0]
                            ?.meetupDateRange ||
                          props.data.wpHomepage.homepage.eventPreview[0]
                            ?.simpleEvents?.simpleDateRange}
                      </p>
                    </div>
                  </Link>
                </div>
              ) : props.data.wpHomepage.homepage.newsPreview ? (
                <div className="upcoming" sx={{ width: `100%` }}>
                  <Link
                    to={`news/${props.data.wpHomepage.homepage.newsPreview[0]?.slug}`}
                  >
                    <div className="upcoming-events">
                      {props.data.wpHomepage.homepage.newsPreview[0]?.title ? (
                        <h3 className="upcoming-headline">
                          {props.data.wpHomepage.homepage.newsPreview[0]?.title}
                        </h3>
                      ) : null}
                      <div className="upcoming-subline">
                        {props.data.wpHomepage.homepage.newsPreview[0]
                          ?.excerpt ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                props.data.wpHomepage.homepage.newsPreview[0]
                                  ?.excerpt,
                            }}
                          ></div>
                        ) : null}
                      </div>
                    </div>
                    <Img
                      fluid={
                        props.data.wpHomepage.homepage.newsPreview[0]?.postLink
                          ?.postImage?.localFile?.childImageSharp?.fluid
                      }
                      style={{
                        width: `100%`,
                        height: `100%`,
                        objectFit: `cover`,
                      }}
                    />
                  </Link>
                </div>
              ) : (
                <p>No events or news at the moment</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {props.data.allWpHomepage?.nodes?.map(e => {
        return (
          <div className="grid-container gap-xxxl">
            {e.homepage.events ? (
              <div>
                <h2>Upcoming</h2>
                <div className="grid-x grid-margin-x grid-margin-y">
                  {e.homepage.events.map(s => {
                    return (
                      <div className="large-6 medium-12 small-12 cell">
                        {s.slug ? (
                          <div className="upcoming" sx={{ width: `100%` }}>
                            {s.slug ? (
                              <Link to={`/event/${s?.slug}/`}>
                                {s.cYAN?.heroImage?.localFile?.childImageSharp
                                  .fluid ? (
                                  <Img
                                    fluid={
                                      s.cYAN?.heroImage?.localFile
                                        ?.childImageSharp?.fluid
                                    }
                                  />
                                ) : s.simpleEvents?.heroImage?.localFile
                                    ?.childImageSharp.fluid ? (
                                  <Img
                                    fluid={
                                      s.simpleEvents?.heroImage?.localFile
                                        ?.childImageSharp?.fluid
                                    }
                                  />
                                ) : (
                                  <Img
                                    fluid={
                                      props.data.eventPlaceholder
                                        .childImageSharp?.fluid
                                    }
                                  />
                                )}
                                {s.title ? (
                                  <div className="upcoming-events">
                                    {s?.title ? (
                                      <h3 className="upcoming-headline">
                                        {s?.title}
                                      </h3>
                                    ) : null}
                                    <div className="upcoming-subline">
                                      {s?.excerpt ? (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: s?.excerpt,
                                          }}
                                        ></div>
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null}
                              </Link>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : null}
          </div>
        )
      })}
      <div className="c2a gap-xl">
        <div className="grid-container">
          <div className="c2a__text">
            {props.data.wpHomepage.homepage.c2a ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: props.data.wpHomepage?.homepage?.c2a,
                }}
              ></div>
            ) : null}
          </div>
          <span sx={{ display: `flex` }}>
            {props.data.wpHomepage?.homepage?.c2aButton1Title ? (
              <div>
                {props.data.wpHomepage?.homepage?.c2aButton1?.url?.startsWith(
                  "http"
                ) ? (
                  <a
                    href={props.data.wpHomepage?.homepage?.c2aButton1?.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="c2a__buttons">
                      {props.data.wpHomepage?.homepage?.c2aButton1Title}
                    </button>
                  </a>
                ) : (
                  <Link to={props.data.wpHomepage?.homepage?.c2aButton1?.url}>
                    <button className="c2a__buttons">
                      {props.data.wpHomepage?.homepage?.c2aButton1Title}
                    </button>
                  </Link>
                )}
              </div>
            ) : null}
            {props.data.wpHomepage?.homepage?.c2aButton2Title ? (
              <div>
                {props.data.wpHomepage?.homepage?.c2aButton2?.url?.startsWith(
                  "http"
                ) ? (
                  <a
                    href={props.data.wpHomepage?.homepage?.c2aButton2?.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button>
                      {props.data.wpHomepage?.homepage?.c2aButton2Title}
                    </button>
                  </a>
                ) : (
                  <div>
                    {props.data.wpHomepage?.homepage?.c2aButton2Title ? (
                      <Link
                        to={props.data.wpHomepage?.homepage?.c2aButton2?.url}
                      >
                        <button>
                          {props.data.wpHomepage?.homepage?.c2aButton2Title}
                        </button>
                      </Link>
                    ) : null}
                  </div>
                )}
              </div>
            ) : null}
          </span>
        </div>
      </div>
      <News posts={props.data.allWpHomepage} />
      <IndustryNews industry={props.data.allWpHomepage} />
      <Footer />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          label
          childItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
    allWpMenu {
      nodes {
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
              }
            }
            label
            url
          }
        }
      }
    }
    allWpSingleIndustryNews(sort: { fields: date, order: DESC }, limit: 4) {
      nodes {
        id
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
        newsLinks {
          linkUrl {
            url
          }
        }
        title
        content
      }
    }
    wpHomepage {
      homepage {
        seoMetaTitle
        seoMetaDescription
        slider {
          image {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 2000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        heroImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        heroText
        registerNow {
          title
          url
        }
        showDescription {
          title
          url
        }
        introduction
        c2a
        c2aButton1 {
          url
        }
        c2aButton2 {
          url
        }
        c2aButton1Title
        c2aButton2Title
        footerImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 800, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        eventPreview {
          ... on WpEventPage {
            id
            title
            excerpt
            slug
            cYAN {
              featuredImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1500, quality: 75) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on WpSimpleEvent {
            id
            title
            slug
            simpleEvents {
              simpleDateRange
              heroImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1500, quality: 75) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
        newsPreview {
          ... on WpPost {
            id
            title
            postLink {
              postImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 50) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              postContent
              postExcerpt
            }
            slug
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            content
          }
        }
      }
    }
    allWpHomepage(filter: { status: { eq: "publish" } }) {
      nodes {
        homepage {
          industryNews {
            ... on WpSingleIndustryNews {
              id
              title
              content
              date(formatString: "MMMM DD, YYYY")
              newsLinks {
                linkUrl {
                  url
                }
              }
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
          eventPreview {
            ... on WpEventPage {
              id
              title
              excerpt
              slug
              cYAN {
                heroImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
            ... on WpSimpleEvent {
              id
              title
              slug
              simpleEvents {
                simpleDateRange
                heroImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
          news {
            ... on WpPost {
              id
              title
              postLink {
                postImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                postContent
                postExcerpt
              }
              slug
              date(formatString: "MMMM DD, YYYY")
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              content
            }
          }
        }
      }
    }
    wpEventPage {
      cYAN {
        news {
          ... on WpPost {
            id
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            title
            categories {
              nodes {
                id
                name
              }
            }
            date(formatString: "MMMM DD, YYYY")
            content
            slug
          }
        }
      }
    }
    wpMeetupEvent {
      title
    }
    allWpSponsor {
      nodes {
        sponsorDetails {
          sponsorLink
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allWpEventPage {
      nodes {
        id
        date
        title
        slug
        cYAN {
          eventSettings {
            eventOver
            collapseSchedule
            sectionSettings
          }
          start
          end
          heroImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          heroHeadline
          heroText
          heroButtons {
            heroButton {
              title
              url
            }
          }
          eventDateRange
          introTitle
          introText
          venueName
          venueAddress
          venueBook {
            title
            url
          }
          contact {
            contactName
            contactUsImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            contactPhone
            contactMail
          }
          descriptionLeft
          descriptionRight
          news {
            ... on WpPost {
              id
              excerpt
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              title
              categories {
                nodes {
                  id
                  name
                }
              }
              date(formatString: "MMMM DD, YYYY")
              content
            }
          }
          file {
            ... on WpFile {
              id
              title
              fileQuery {
                fileDescription
                file {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
          footerImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    allWpSimpleEvent(sort: { fields: date, order: DESC }) {
      nodes {
        slug
        id
        date
        title
        simpleEvents {
          start
          end
          simpleDateRange
          heroImage {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          timeSlots {
            startTime
            endTime
          }
        }
      }
    }
    allWpMeetupEvent(sort: { fields: date, order: DESC }) {
      nodes {
        slug
        id
        date
        endDate
        content
        startDate
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    conference: file(relativePath: { eq: "IMG_9700.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400, maxHeight: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    tech: file(relativePath: { eq: "tech-conference.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 2000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    summit: file(relativePath: { eq: "summit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    emea: file(relativePath: { eq: "emea.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    people: file(relativePath: { eq: "group-of-people.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    julien: file(relativePath: { eq: "julien-backhaus.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    eventPlaceholder: file(relativePath: { eq: "file-updates.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
